import { AuthService } from 'src/app/core/services/auth.service';
import { DataModelService } from './../../core/services/datamodel.service';
import { ProjectService } from './../../core/services/project.service';
import { TestCaseService } from './../../core/services/testcase.service';
import { UserStoryService } from './../../core/services/user-story.service';
import { JourneyService } from './../../core/services/journey.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly journeyService: JourneyService,
    private readonly userStoryService: UserStoryService,
    private readonly testCaseService: TestCaseService,
    private readonly projectService: ProjectService,
    private readonly dataModelService: DataModelService,
    private readonly authService: AuthService
  ) {}
  projectId: string;
  journeyList: any;
  projectName: any;
  hideForNormalUser: boolean = false;
  ngOnInit(): void {
    this.activatedRoute.url.subscribe(
      (urlSegment) => (this.projectId = urlSegment[1].path)
    );

    this.projectService.projectList$.subscribe((data) => {
      this.projectName = [...data].filter(
        (data) => data.projectid === this.projectId
      )[0]?.name;
    });

    this.journeyService.journeyList$.subscribe((data) => {
      this.journeyList = [...data];
    });

    this.hideForNormalUser = this.authService.isNormalUser();
  }

  goToJourneyFlow() {
    this.router.navigate(['dashboard/create-journey', this.projectId]);
  }

  goToTestScenarioDetailsPage() {
    this.testCaseService
      .getTestCasesFromDB(localStorage.getItem('email_id'), this.projectId)
      .subscribe((data) => {
        if (data.status) {
          this.router.navigate([
            '/dashboard/create-test-scenarios',
            this.projectId,
          ]);
          this.testCaseService.testCasesSub.next([...data.testcaseList]);
        }
      });
  }

  goToUserStoriesDetailsPage() {
    this.userStoryService
      .getUserStoriesFromDB(localStorage.getItem('email_id'), this.projectId)
      .subscribe((data) => {
        this.userStoryService.userStoriesSub.next([...data.userStories]);
      });
    this.router.navigate(['/dashboard/create-user-stories', this.projectId]);
  }

  goToDataModelsPage() {
    this.dataModelService
      .getDataModelsFromDB(localStorage.getItem('email_id'), this.projectId)
      .subscribe((data) => {
        this.dataModelService.dataModelSub.next([...data.dmList]);
      });

    this.router.navigate(['/dashboard/create-data-model', this.projectId]);
  }

  deleteJourney(id: string) {
    this.journeyService.deleteJourneyFromDB(id).subscribe((data) => {
      if (data.status === 1) {
        this.journeyList = this.journeyList.filter(
          (journey) => journey.journeyid !== id
        );
        this.journeyService.journeyListSubject.next([...this.journeyList]);
      } else {
        alert('Something went wrong, Please try again');
      }
    });
  }
}
