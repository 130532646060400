import { baseAPIPath } from './../../../config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class WorkFlowDetailsService {
  constructor(private readonly http: HttpClient) {}

  getWorkFlowDetailsByElementFromAPI(req: any) {
    return this.http
      .post<any>(`${baseAPIPath}/getWorkFlowDetailsByElement`, req)
      .subscribe((data) => console.log(data));
  }

  // getUserStoriesForWorkFlowElementFromAPI(req: any) {
  //   return this.http
  //     .post<any>(`${baseAPIPath}/getUserStories`, req)
  //     .subscribe((data) => console.log(data));
  // }

  getBusinessRulesForWorkFowElementFromAPI(req: any) {
    return this.http
      .post<any>(`${baseAPIPath}/getBusinessRules`, req)
      .subscribe((data) => console.log(data));
  }

  getTestScenariosForWorkFlowElementFromAPI(req: any) {
    return this.http
    .post<any>(`${baseAPIPath}/getTestCases`, req)
  }
}
