import { DashboardModule } from './../dashboard/dashboard.module';
import { Routes } from '@angular/router';
import { ProjectListComponent } from '../project/project-list/project-list.component';
import { CreateProjectComponent } from '../project/create-project/create-project.component';
import { ProjectDetailsComponent } from '../project/project-details/project-details.component';

export const BACKEND_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'project',
    loadChildren: () =>
      import('../project/project.module').then((m) => m.ProjectModule),
  },
];
