<div class="ui-tool-flex-container container">
  <app-back-button></app-back-button>
  <h2>{{ screenType === "edit" ? "Edit Project" : "New Project" }}</h2>
</div>
<div class="container ux-tool-create-project-form">
  <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label class="form-label">Title</label>
      <input
        type="text"
        class="form-control"
        formControlName="title"
        required
      />
    </div>
    <div class="create-project-form-desc-container">
      <div class="form-group mt-4">
        <label class="form-label">Description</label>
        <textarea
          type="text"
          class="form-control"
          placeholder="Description"
          formControlName="shortDescription"
          required
        ></textarea>
      </div>
      <div class="form-group mt-4">
        <label class="form-label">Owner</label>
        <input
          type="text"
          class="form-control"
          placeholder="Owner"
          formControlName="longDescription"
          required
        />
      </div>
      <div class="form-group mt-4">
        <label class="form-label">Date</label>
        <input
          type="date"
          class="form-control"
          formControlName="startDate"
          required
        />
      </div>
      <div class="form-group mt-4">
        
      </div>
    </div>
    <div class="d-grid gap-2 margin-top-40">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="!projectForm.valid"
      >
        {{ screenType === "edit" ? "Update Project" : "Create Project" }}
      </button>
    </div>
  </form>
</div>
