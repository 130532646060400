import { baseAPIPath } from './../../../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Journey } from './../model/journey.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class JourneyService {
  projectId: any;
  constructor(private readonly http: HttpClient) {}

  journeyListSubject = new BehaviorSubject<any[]>([]);
  journeyList$ = this.journeyListSubject.asObservable();

  editJourneySubject = new BehaviorSubject<any>({});
  editJourney$ = this.editJourneySubject.asObservable();

  getJourneyFromAPI(email, projectId) {
    return this.http
      .post<any>(`${baseAPIPath}/journeys`, { email, projectId })
      .subscribe((data) => {
        this.journeyListSubject.next([...data.journeyDetails]);
      });
  }

  updateJourney(journey: any) {
    return this.http.put<any>(`${baseAPIPath}/journey`, journey);
  }

  addNewJourney(journey: Journey) {
    return this.http.post<any>(`${baseAPIPath}/addjourney`, journey);
  }

  deleteJourneyFromDB(journeyId: string) {
    return this.http.request<any>('delete', `${baseAPIPath}/journey`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { journeyid: journeyId },
    });
  }

  fetchProjectIssues(projectKey: string) {
    this.http
      .get<any>(`${baseAPIPath}/getProject/?project=${projectKey}`)
      .pipe(
        map((data) => {
          return data.issueTypes.map((data) => ({
            storyId: data.id,
            storyDescription: data.description,
          }));
        })
      )
      .subscribe((data) => {
        const previousData = this.journeyListSubject.value;
        this.journeyListSubject.next([...previousData, ...data]);
      });
  }
}
