import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input() showMe: boolean = false;

  notificationList: any = ([] = [
    {
      id: 1,
      title: 'Checkout your message one.',
      timestamp: '12/10/2021 4:00 PM',
    },
    {
      id: 2,
      title: 'Checkout your message two.',
      timestamp: '12/08/2021 8:00 PM',
    },
  ]);
}
