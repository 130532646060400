import { AddCollaboratorComponent } from './add-collaborator/add-collaborator.component';
import { WorkflowDescriptionComponent } from './workflow-description/workflow-description.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JourneyListComponent } from './journey-list/journey-list.component';
import { CreateJourneyComponent } from './create-journey/create-journey.component';
import { JourneyDescriptionComponent } from './journey-description/journey-description.component';

export const routes: Routes = [
  {
    path: '',
    component: ProjectListComponent,
  },
  {
    path: 'project-list',
    component: ProjectListComponent,
  },
  {
    path: 'create-project',
    component: CreateProjectComponent,
  },
  {
    path: 'edit-project/:id/:type',
    component: CreateProjectComponent,
  },
  {
    path: 'project-description/:id',
    component: ProjectDetailsComponent,
  },
  {
    path: 'journey-list',
    component: JourneyListComponent,
  },
  {
    path: 'create-journey/:id',
    component: CreateJourneyComponent,
  },
  {
    path: 'edit-journey/:id/:type',
    component: CreateJourneyComponent,
  },
  {
    path: 'journey-description/:id',
    component: JourneyDescriptionComponent,
  },
  {
    path: 'journey-description/:id/workflow-description/:el',
    component: WorkflowDescriptionComponent,
  },
  {
    path: 'add-collaborator',
    component: AddCollaboratorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}
