<div class="container notification-container" *ngIf="showMe">
  <h5>Notifications</h5>
  <div
    class="card custom-notification-card"
    key="notification.id"
    *ngFor="let notification of notificationList"
  >
    {{ notification.title }}
  </div>
</div>
  