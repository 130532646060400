import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { baseAPIPath } from 'src/config';

@Injectable({
  providedIn: 'root',
})
export class TestCaseService {
  testCasesSub = new BehaviorSubject<any[]>([]);

  testCases$ = this.testCasesSub.asObservable();
  constructor(private readonly http: HttpClient) {}

  getTestCasesFromDB(email, projectId) {
    return this.http.post<any>(`${baseAPIPath}/getTestCases`, {
      email_id: email,
      projectid: projectId,
    });
  }

  saveTestCases(testCaseList: any) {
    this.http
      .post<any>(`${baseAPIPath}/testcase`, testCaseList)
      .subscribe((data) => console.log(data));
  }

  addTestCase(testCase) {
    return this.http.post<any>(`${baseAPIPath}/addtestcase`, testCase);
    // this.testCasesSub.next([...testCase]);
  }

  deleteTestCaseFromDB(id: string) {
    return this.http.request<any>('delete', `${baseAPIPath}/deletetestcase`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { testcaseid: id },
    });
  }

  updateTestCase(testCase: any) {
    return this.http.put<any>(`${baseAPIPath}/testcase`, testCase);
  }
}
