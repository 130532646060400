import { WorkFlowService } from './../../core/services/workFlow.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-experience-form',
  templateUrl: './experience-form.component.html',
  styleUrls: ['./experience-form.component.scss'],
})
export class ExperienceFormComponent implements OnInit {
  userStoryselectedItems: any[];
  testCaseDropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    selectAllText: string;
    unSelectAllText: string;
    itemsShowLimit: number;
    allowSearchFilter: boolean;
  };
  testCaseSelectedItems: any[];
  userStorydropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    selectAllText: string;
    unSelectAllText: string;
    itemsShowLimit: number;
    allowSearchFilter: boolean;
  };
  constructor(private readonly workFlowService: WorkFlowService) {}
  @Output() closeForm = new EventEmitter<boolean>();
  @Input() taskDescription: string;
  @Input() propertyLink: string;
  @Input() userStoryList: any[];
  @Input() testCaseList: any[];
  @Input() dataModelList: any[];
  selectedEl: any;
  workFlow = [];

  dataModelselectedItems = [];
  dataModeldropdownSettings = {};

  experienceForm = new FormGroup({
    taskDescription: new FormControl(''),
    propertyLink: new FormControl(''),
    dataModelName: new FormControl(''),
    storyId: new FormControl(''),
    testScenarios: new FormControl(''),
  });

  ngOnInit(): void {
    this.selectedEl = {
      ...this.workFlowService.selectedElementOfWorkFlow,
    };

    // datamodel transformation code
    this.dataModelList = this.dataModelList.map((dm) => ({
      ...dm,
      isSelected: false,
    }));

    this.dataModelList.forEach((dm) => {
      if (
        this.workFlowService.selectedElementOfWorkFlow.dataModelList.filter(
          (d) => d.datamodelid === dm.datamodelid
        ).length > 0
      ) {
        dm.isSelected = true;
      }
    });

    this.dataModelselectedItems = this.dataModelList.filter(
      (dm) => dm.isSelected
    );
    this.dataModeldropdownSettings = {
      singleSelection: false,
      idField: 'datamodelid',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    // storylist transformation code
    this.userStoryList = this.userStoryList.map((us) => ({
      ...us,
      isSelected: false,
    }));

    this.userStoryList.forEach((us) => {
      if (
        this.workFlowService.selectedElementOfWorkFlow.storyList.filter(
          (s) => s.storyid === us.storyid
        ).length > 0
      ) {
        us.isSelected = true;
      }
    });

    this.userStoryselectedItems = this.userStoryList.filter(
      (us) => us.isSelected
    );
    this.userStorydropdownSettings = {
      singleSelection: false,
      idField: 'userstoryid',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    // testcase transformation code
    this.testCaseList = this.testCaseList.map((ts) => ({
      ...ts,
      isSelected: false,
    }));

    console.log(this.testCaseList);

    this.testCaseList.forEach((ts) => {
      if (
        this.workFlowService.selectedElementOfWorkFlow.testCaseList.filter(
          (t) => t.testcaseid === ts.testcaseid
        ).length > 0
      ) {
        ts.isSelected = true;
      }
    });

    this.testCaseSelectedItems = this.testCaseList.filter(
      (ts) => ts.isSelected
    );
    this.testCaseDropdownSettings = {
      singleSelection: false,
      idField: 'testcaseid',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    // get available value form workflow
    this.workFlowService.workFlow$.subscribe((data) => {
      this.workFlow = [...data];
    });

    this.workFlow.forEach((el) => {
      if (el.id === this.selectedEl.id) {
        this.experienceForm.value.taskDescription = el.taskDescription;
        this.experienceForm.patchValue({
          taskDescription: el.taskDescription,
          propertyLink: el.propertyLink,
          dataModelName: el.dataModelList,
          storyId: el.storyList,
          testScenarios: el.testCaseList,
        });
      }
    });
  }

  onItemSelect(item: any) {
    if (item.hasOwnProperty('datamodelid')) {
      this.dataModelList = this.dataModelList.map((dm) => {
        if (dm.datamodelid === item.datamodelid) {
          dm.isSelected = true;
          return dm;
        }
      });
    } else if (item.hasOwnProperty('userstoryid')) {
      this.userStoryList = this.userStoryList.map((us) => {
        if (us.userstoryid === item.userstoryid) {
          us.isSelected = true;
          return us;
        }
      });
    } else if (item.hasOwnProperty('testcaseid')) {
      this.testCaseList = this.testCaseList.map((ts) => {
        if (ts.testcaseid === item.testcaseid) {
          ts.isSelected = true;
          return ts;
        }
      });
    }
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  onSubmit() {
    const fData = this.experienceForm.value;
    this.workFlowService.workFlow$.subscribe((data) => {
      this.workFlow = [...data];
    });
    this.workFlow = this.workFlow.map((d) => {
      if (d.id === this.selectedEl.id) {
        d = {
          id: this.selectedEl.id,
          type: this.selectedEl.type,
          taskDescription: fData.taskDescription,
          propertyLink: fData.propertyLink,
          dataModelList: [...this.dataModelselectedItems],
          storyList: [...this.userStoryselectedItems],
          testCaseList: [...this.testCaseSelectedItems],
        };
      }

      return d;
    });
    this.workFlowService.workFlowSubject.next([...this.workFlow]);
    this.closeForm.emit(false);
    this.workFlowService.saveExpFormData(this.experienceForm.value);
  }

  closeFormFn() {
    this.closeForm.emit(false);
  }
}
