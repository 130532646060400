import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { baseAPIPath } from 'src/config';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthService {
  constructor(private readonly router: Router, private http: HttpClient) {}

  signup(userInfo: any) {
    return this.http
      .post<any>(`${baseAPIPath}/signup`, userInfo)
      .pipe(catchError(this.handleError));
  }
  login(userInfo: any) {
    return this.http.post<any>(`${baseAPIPath}/login`, userInfo);
  }

  isLoggedInSessionActive() {
    if (localStorage.getItem('email_id')) {
      return true;
    }

    return false;
  }

  logout() {
    localStorage.removeItem('email_id');
    localStorage.removeItem('userType');
    this.router.navigate(['/']);
    window.location.href = '/';
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  isNormalUser(): boolean {
    return localStorage.getItem('userType') === 'user';
  }
}
