import { AuthService } from 'src/app/core/services/auth.service';
import { DataModelService } from './../../core/services/datamodel.service';
import { TestCaseService } from './../../core/services/testcase.service';
import { UserStoryService } from './../../core/services/user-story.service';
import { WorkFlowDetailsService } from './../../core/services/workFlowDetails.service';
import { WorkFlowService } from './../../core/services/workFlow.service';
import { UtilService } from './../../core/services/util.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import { JourneyService } from 'src/app/core/services/journey.service';
import { TreelistService } from 'src/app/core/services/treelist.service';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { ModalService } from 'src/app/core/services/modal.service';

@Component({
  selector: 'app-journey-description',
  templateUrl: './journey-description.component.html',
  styleUrls: ['./journey-description.component.scss'],
})
export class JourneyDescriptionComponent implements OnInit {
  workFlowId: string;
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly utilService: UtilService,
    private readonly workFlowService: WorkFlowService,
    private readonly router: Router,
    private readonly workFlowDetailsService: WorkFlowDetailsService,
    private readonly journeyService: JourneyService,
    private readonly treelistsvc: TreelistService,
    private readonly userStoryService: UserStoryService,
    private readonly testCaseService: TestCaseService,
    private readonly dataModelService: DataModelService,
    private readonly authService: AuthService,
    private readonly modalService: ModalService
  ) {
    this.treelistsvc.treeToggler$.subscribe((status) => {
      this.currentlySelectedTree = treelistsvc.currentlySelectedTree;
      this.currentlySelectedTreeId = treelistsvc.currentlySelectedTreeId;
      this.treelistsvc.clearHiddenChildren();
    });

    for (const c of this.data.children) {
      const alist = [];
      alist.push(c);
      this.treeLists.push([alist]);
    }

    this.treelistsvc.setCurrentlySelectedTree(this.treeLists[0]);
    this.currentlySelectedTree = treelistsvc.currentlySelectedTree;
    this.currentlySelectedTreeId = this.currentlySelectedTree[0][0].id;

    this.workFlowService.formData$.subscribe((data) => {
      this.elementProperties = { ...data };
    });
  }

  data = {
    name: 'root',
    children: [
      {
        name: 'Parent',
        description: 'Production',
        id: '1',
        datecreated: '2010-10-10',
        installid: 'WeShipAnythingProd',
        showchildren: '1',
        children: [
          {
            name: 'Child 1',
            description: 'User acceptance testing EU',
            id: '2',
            datecreated: '2018-7-05',
            showchildren: '1',
            children: [],
          },
          {
            name: 'Child 2',
            description: 'User acceptance testing EU',
            id: '3',
            datecreated: '2018-7-05',
            showchildren: '1',
            children: [],
          },
        ],
      },
    ],
  };

  activeElement: string;
  activeTab: string;
  messagingText: string;
  experienceText: string;
  currentlySelectedTree;
  currentlySelectedTreeId;
  treeLists = [];
  elementProperties: any = {};
  elementName: string;
  journeyId: string;
  formVisibility: boolean;
  userStories: any[] = [];
  testScenaios: any[] = [];
  dataModels: any[] = [];
  filteredDataModel: any[] = [];
  filteredUserStories: any[] = [];
  filteredTestCases: any[] = [];
  projectId: string;
  hideForNormalUser: boolean = false;
  experienceFormSelectedValues: any;
  workFlow: any[] = [];
  journeyName: string;
  isUpdateVisible: boolean = false;
  activedWorkFlow: any = {};

  @ViewChild('expForm') expForm: any;
  ngOnInit(): void {
    this.hideForNormalUser = this.authService.isNormalUser();
    this.activatedRoute.url.subscribe((urlSegment) => {
      this.journeyId = urlSegment[1].path;
    });

    this.journeyService.journeyList$.subscribe((data) => {
      this.journeyName = [...data].filter((d) => {
        const splitted = d.journeyid.split('#');
        this.projectId = splitted[0];
        return splitted[1] === this.journeyId;
      })[0].name;
    });

    this.workFlowService
      .getWorkFlowFromDB(
        localStorage.getItem('email_id'),
        this.projectId,
        this.journeyId
      )
      .subscribe((data) => {
        if (data.status) {
          this.workFlow = [...data.workFlowInfo];
          this.workFlowId = data.workFlowInfo[0]?.workflowid;
          if (this.workFlow.length === 0) {
            this.workFlow = [
              {
                type: 'start',
                id: `start#${this.utilService.generateRandomId()}`,
                status: 1,
              },
            ];
          } else if (this.workFlow.length > 0) {
            this.workFlow = [...data.workFlowInfo[0].workflowdetails];
            this.isUpdateVisible = true;
          }
        }
      });

    this.workFlowService.workFlow$.subscribe((data) => {
      this.workFlow = [...data];
    });

    this.workFlowService.formData$.subscribe((data) => {
      this.experienceFormSelectedValues = { ...data };
    });

    this.dataModelService
      .getDataModelsFromDB(localStorage.getItem('email_id'), this.projectId)
      .subscribe((data) => {
        if (data.status) {
          this.dataModels = [...data.dmList];
        }
      });

    this.userStoryService
      .getUserStoriesFromDB(localStorage.getItem('email_id'), this.projectId)
      .subscribe((data) => {
        if (data.status) {
          this.userStories = [...data.userStories];
        }
      });

    this.testCaseService
      .getTestCasesFromDB(localStorage.getItem('email_id'), this.projectId)
      .subscribe((data) => {
        if (data.status) {
          this.testScenaios = [...data.testcaseList];
        }
      });

    this.activeElement = 'start';
    this.activeTab = 'workflow';
  }

  ddd = [
    {
      name: 'Parent',
      description: 'des',
      id: this.utilService.generateRandomId(),
      datecreated: new Date(),
      installid: 'WeShipAnythingProd',
      showchildren: '1',
      children: [
        {
          name: '',
          description: '',
          id: '2',
          datecreated: '2018-7-05',
          showchildren: '1',
          children: [],
        },
      ],
    },
  ];

  elements = [
    'experience',
    'messaging',
    'database',
    'system',
    'decision',
    'end',
  ];
  drop(event: CdkDragDrop<number[]>) {
    const droppedElement =
      event.item.element.nativeElement.innerText.toLowerCase();
    switch (droppedElement) {
      case 'experience':
        this.workFlow.push({
          id: `e#${this.utilService.generateRandomId()}`,
          type: 'experience',
          taskDescription: '',
          propertyLink: '',
          dataModelList: [],
          storyList: [],
          testCaseList: [],
        });
        break;
      case 'system':
        this.workFlow.push({
          id: `s#${this.utilService.generateRandomId()}`,
          type: 'system',
          taskDescription: '',
          propertyLink: '',
          dataModelList: [],
          storyList: [],
          testCaseList: [],
        });
        break;
      case 'messaging':
        this.workFlow.push({
          id: `m#${this.utilService.generateRandomId()}`,
          type: 'messaging',
          taskDescription: '',
          propertyLink: '',
          dataModelList: [],
          storyList: [],
          testCaseList: [],
        });
        break;
      case 'database':
        this.workFlow.push({
          id: `d#${this.utilService.generateRandomId()}`,
          type: 'database',
          taskDescription: '',
          propertyLink: '',
          dataModelList: [],
          storyList: [],
          testCaseList: [],
        });
        break;
      case 'end':
        if (
          (this.workFlow.includes('end') && droppedElement === 'end') ||
          this.workFlow[this.workFlow.length - 1] === 'end'
        ) {
          alert('Not allowed');
          return;
        }
        this.workFlow.push({
          id: `end#${this.utilService.generateRandomId()}`,
          type: 'end',
          status: 1,
        });
        break;
      default:
        alert('Invalid action');
    }
    this.workFlowService.workFlowSubject.next([...this.workFlow]);
  }

  showForm() {
    this.formVisibility = !this.formVisibility;
  }

  renderFilteredDataMode() {
    // this.filteredDataModel = this.utilService.duplicateItems(
    //   this.dataModels,
    //   this.activedWorkFlow.dataModelList
    // );

    this.filteredDataModel = this.dataModels.filter((dt) => {
      return this.activedWorkFlow.dataModelList.find((d) => {
        return d.datamodelid === dt.datamodelid;
      });
    });
    console.log('filteredDataModel ', this.filteredDataModel);
    const tempArray = [];
    for (const c of this.filteredDataModel[0].datamodelDetails.children) {
      const alist = [];
      alist.push(c);
      tempArray.push([alist]);
    }
    console.log(tempArray[0]);
    this.treelistsvc.setCurrentlySelectedTree(tempArray[0]);
    this.currentlySelectedTree = this.treelistsvc.currentlySelectedTree;
  }

  renderFilteredUserStories() {
    // this.filteredUserStories = this.utilService.duplicateItems(
    //   this.userStories,
    //   this.activedWorkFlow.storyList
    // );

    this.filteredUserStories = this.userStories.filter((us) => {
      return this.activedWorkFlow.storyList.find(
        (s) => s.storyid === us.storyid
      );
    });

    console.log(this.filteredUserStories);
  }

  renderFilteredTestCases() {
    // this.filteredTestCases = this.utilService.duplicateItems(
    //   this.testScenaios,
    //   this.activedWorkFlow.testCaseList
    // );

    this.filteredTestCases = this.testScenaios.filter((ts) => {
      return this.activedWorkFlow.testCaseList.find(
        (t) => t.testcaseid === ts.testcaseid
      );
    });

    console.log(this.filteredTestCases);
  }

  onJourneySave() {
    const req = {
      email_id: localStorage.getItem('email_id'),
      projectid: this.projectId,
      journeyid: this.journeyId,
      workflowid: `${this.utilService.generateRandomId()}#${this.projectId}#${
        this.journeyId
      }`,
      workflowdetails: this.workFlow,
    };
    this.workFlowService.addWorkFlow(req).subscribe((data) => {
      if (data.status) {
        this.workFlowService.workFlowSubject.next([...this.workFlow]);
        this.modalService.showModal(`Journey saved successfully`);
      }
    });
  }

  goToWorkFlow(info: any) {
    this.activeElement = info.type;
    this.activedWorkFlow = {
      ...info,
    };
    this.elementProperties = {
      taskDescription: this.activedWorkFlow.taskDescription,
      propertyLink: this.activedWorkFlow.propertyLink,
      dataModelName: this.activedWorkFlow.dataModelList,
      storyId: this.activedWorkFlow.storyList,
      testScenarios: this.activedWorkFlow.testCaseList,
    };
    // this.activeTab = this.activedWorkFlow.type;
  }

  deleteElement(id: string) {
    const deleteIt = confirm('Do you want to delete');
    if (!deleteIt) {
      return;
    }
    this.workFlow = this.workFlow.filter((data) => data.id !== id);
  }

  resetWorkFlow() {
    const doReset = confirm('Do you want to reset workflow?');
    if (!doReset) {
      return;
    } else {
      this.workFlow = [
        {
          type: 'start',
          id: `start#${this.utilService.generateRandomId()}`,
          status: 1,
        },
      ];
      this.activeElement = 'start';
      this.activeTab = 'workflow';
      this.workFlowService.workFlowSubject.next([...this.workFlow]);
    }
  }

  changeFormVis(e: boolean) {
    this.formVisibility = e;
  }

  editElement(details: any) {
    this.workFlowService.selectedElementOfWorkFlow = {
      ...details,
    };
    this.workFlow.map((wf) => {
      if (wf.id === details.id) {
        wf = details;
      }
    });
    this.formVisibility = true;
    this.workFlowService.workFlowSubject.next([...this.workFlow]);
  }

  onJourneyUpdate() {
    console.log(this.workFlow);
    const req = {
      email_id: localStorage.getItem('email_id'),
      projectid: this.projectId,
      journeyid: this.journeyId,
      workflowid: this.workFlowId,
      workflowdetails: this.workFlow,
    };
    this.workFlowService.updateWorkFlowToDB(req).subscribe((data) => {
      if (data.status) {
        this.workFlowService.workFlowSubject.next([...this.workFlow]);
        this.modalService.showModal(`Journey saved successfully`);
      }
    });
  }
}
