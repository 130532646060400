import { TestCaseService } from './../../core/services/testcase.service';
import { UserStoryService } from './../../core/services/user-story.service';
import { WorkFlowService } from './../../core/services/workFlow.service';
import { WorkFlowDetailsService } from './../../core/services/workFlowDetails.service';
import { TreelistService } from './../../core/services/treelist.service';
import { UtilService } from './../../core/services/util.service';
import { JourneyService } from './../../core/services/journey.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workflow-description',
  templateUrl: './workflow-description.component.html',
  styleUrls: ['./workflow-description.component.scss'],
})
export class WorkflowDescriptionComponent implements OnInit {
  data = {
    name: 'root',
    children: [
      {
        name: 'Parent',
        description: 'Production',
        id: '1',
        datecreated: '2010-10-10',
        installid: 'WeShipAnythingProd',
        showchildren: '1',
        children: [
          {
            name: 'Child 1',
            description: 'User acceptance testing EU',
            id: '2',
            datecreated: '2018-7-05',
            showchildren: '1',
            children: [],
          },
          {
            name: 'Child 2',
            description: 'User acceptance testing EU',
            id: '3',
            datecreated: '2018-7-05',
            showchildren: '1',
            children: [],
          },
        ],
      },
    ],
  };
  currentlySelectedTree;
  currentlySelectedTreeId;
  treeLists = [];
  elementProperties: any = {};
  elementName: string;
  constructor(
    private readonly router: Router,
    private readonly journeyService: JourneyService,
    private readonly utilService: UtilService,
    private readonly treelistsvc: TreelistService,
    private readonly workFlowDetailsService: WorkFlowDetailsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly workFlowService: WorkFlowService,
    private readonly userStoryService: UserStoryService,
    private readonly testCaseService: TestCaseService
  ) {
    this.treelistsvc.treeToggler$.subscribe((status) => {
      this.currentlySelectedTree = treelistsvc.currentlySelectedTree;
      this.currentlySelectedTreeId = treelistsvc.currentlySelectedTreeId;
      this.treelistsvc.clearHiddenChildren();
    });

    for (const c of this.data.children) {
      const alist = [];
      alist.push(c);
      this.treeLists.push([alist]);
    }

    this.treelistsvc.setCurrentlySelectedTree(this.treeLists[0]);
    this.currentlySelectedTree = treelistsvc.currentlySelectedTree;
    this.currentlySelectedTreeId = this.currentlySelectedTree[0][0].id;

    this.workFlowService.formData$.subscribe(data => {
      console.log(data);
      this.elementProperties = {...data};
    })
    // this.elementProperties = {
    //   taskDescription: 'Bankers Information',
    //   protoTypeLink:
    //     'https://xd.adobe.com/embed/bf91b63f-164e-4a67-ba01-b902bed1286b-eac2/',
    //   dataModelName: ['DM1'],
    //   userStories: [1001, 1002],
    //   testScenarios: [2001, 2002],
    // };
  }

  userStories: any[] = [];
  testScenaios: any[] = [];
  ngOnInit() {
    this.activatedRoute.url.subscribe((urlSegment) => {
      this.elementName = urlSegment[3].path;
    });
    
    this.userStoryService.userStories$.subscribe(data => {
      this.userStories = [...data];
    });

    this.testCaseService.testCases$.subscribe(data => {
      this.testScenaios = [...data];
    })
  }


  goToWorkFlow() {
    this.router.navigate['journey-description/1/workflow-description'];
  }

  goBackToWorkFlow() {
    this.utilService.backToPreviousPage();
  }

  // renderUserStories() {
  //   const req = [1];
  //   this.workFlowDetailsService.getUserStoriesForWorkFlowElementFromAPI(req);
  // }
  renderBusinessRules() {
    const req = [1];
    this.workFlowDetailsService.getBusinessRulesForWorkFowElementFromAPI(req);
  }

  renderTestScenarios() {
    const req = [1];
    this.workFlowDetailsService.getTestScenariosForWorkFlowElementFromAPI(req);
  }
}
