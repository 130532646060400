import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable()
export class UtilService {
  constructor(private _location: Location) {}

  generateRandomId(): string {
    return Math.floor(new Date().valueOf() * Math.random()) + '';
  }

  backToPreviousPage() {
    this._location.back();
  }

  duplicateItems(arr1, arr2) {
    if(!arr1 || !arr2 || arr1.length === 0
      || arr2.length === 0) {
        return [];
      }
    return arr1.filter((value) => {
      if ('datamodelid' in value) {
        return arr2.includes(value.datamodelid);
      } else if ('userstoryid' in value) {
        return arr2.includes(value.userstoryid);
      } else if ('testcaseid' in value) {
        return arr2.includes(value.testcaseid);
      }
    });
  }
}
