<div class="ux-tool-menu-bar container">
  <app-back-button></app-back-button>
  <h2>Project: {{ projectName }}</h2>
  <nav class="navbar navbar-expand-lg navbar-light pull-right">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" (click)="goToDataModelsPage()">Data Models</a>
      </li>
      <li class="nav-item">
        <span class="nav-link" (click)="goToUserStoriesDetailsPage()"
          >User Stories</span
        >
      </li>
      <li class="nav-item">
        <span class="nav-link" (click)="goToTestScenarioDetailsPage()"
          >Test Scenarios</span
        >
      </li>
    </ul>
  </nav>
  <button
    *ngIf="!hideForNormalUser"
    type="button"
    class="btn btn-success"
    (click)="goToJourneyFlow()"
  >
    <i class="fa fa-plus"></i> New Workflow
  </button>
</div>
<div class="container ui-tool-flex-container mt-4">
  <ng-container *ngFor="let journey of journeyList">
    <app-custom-card
      [details]="journey"
      (deleteClickHandler)="deleteJourney($event)"
    ></app-custom-card>
  </ng-container>
</div>
