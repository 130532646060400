<div class="card ui-tool-card" style="width: 18rem; height: 20rem">
  <div class="card-body">
    <div class="card-body-header">
      <h5 class="card-title">{{ cardDetails.name }}</h5>
      <div
        *ngIf="!hideForNormalUser"
        style="display: flex; justify-content: space-between; width: 20%"
      >
        <i class="fa fa-trash" (click)="onDelete(cardDetails)"></i>
        <i class="fa fa-edit" (click)="onEdit(cardDetails)"></i>
      </div>
    </div>
    <p class="card-text mt-4" style="height: 50px; overflow: auto">
      {{ cardDetails.shortDescription }}
    </p>
    <p class="card-text mt-4">
      {{ cardDetails.longDescription }}
    </p>
    <h6 class="card-subtitle mb-2 text-muted mt-4">
      Request Date: {{ cardDetails.requestDate }}
    </h6>
    <div class="custom-btn-container">
      <button
        type="button"
        class="btn btn-primary"
        (click)="viewDetails(cardDetails)"
      >
        View
      </button>
    </div>
  </div>
</div>
