import { BehaviorSubject } from 'rxjs';
import { baseAPIPath } from 'src/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class DataModelService {
  constructor(private readonly http: HttpClient) {}

  saveDataModelsInDB(dataModel: any) {
    this.http
      .post<any>(`${baseAPIPath}/datamodel`, dataModel)
      .subscribe((data) => console.log(data));
  }

  dataModelSub = new BehaviorSubject<any[]>([]);

  dataModel$ = this.dataModelSub.asObservable();

  getDataModelsFromDB(email, projectId) {
    return this.http.post<any>(`${baseAPIPath}/datamodel`, {
      email_id: email,
      projectid: projectId,
    });
  }

  saveDataModelToDB(dataModelInfo) {
    return this.http.post<any>(`${baseAPIPath}/adddatamodel`, dataModelInfo);
  }

  updateDataModelToDB(dataModelInfo) {
    return this.http.put<any>(`${baseAPIPath}/datamodel`, dataModelInfo);
  }

  deleteDataModelFromDB(id) {
    return this.http.request<any>('delete', `${baseAPIPath}/deletedatamodel`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { datamodelid: id },
    });
  }
}
