import { UtilService } from '../../core/services/util.service';
import { JourneyService } from '../../core/services/journey.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { Component, OnInit } from '@angular/core';
``;
import { Journey } from 'src/app/core/model/journey.model';

@Component({
  selector: 'app-create-journey',
  templateUrl: './create-journey.component.html',
  styleUrls: ['./create-journey.component.scss'],
})
export class CreateJourneyComponent implements OnInit {
  screenType: string;
  editJourneyInfo: any;
  constructor(
    private readonly router: Router,
    private readonly journeyService: JourneyService,
    private utilService: UtilService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  projectidFromUrl: string;

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((urlSeg) => {
      this.projectidFromUrl = urlSeg[1].path.split('#')[0];
      this.screenType = urlSeg[2]?.path ?? 'create';
    });
    console.log(this.projectidFromUrl);

    if (this.screenType === 'edit') {
      this.journeyService.editJourney$.subscribe((data) => {
        if (data) {
          this.editJourneyInfo = {
            ...data,
          };

          this.journeyForm.patchValue({
            title: data.name,
            shortDescription: data.shortDescription,
            longDescription: data.longDescription,
            startDate: data.requestDate,
          });
        }
      });
    }
  }

  journeyForm = new FormGroup({
    title: new FormControl(''),
    shortDescription: new FormControl(''),
    longDescription: new FormControl(''),
    startDate: new FormControl(''),
  });

  createNewJourney(newJourney: Journey) {
    this.journeyService.addNewJourney(newJourney).subscribe((data) => {
      if (data.status === 1) {
        const currentJourney = this.journeyService.journeyListSubject.value;
        this.journeyService.journeyListSubject.next([
          ...currentJourney,
          newJourney,
        ]);
      }
    });
    this.utilService.backToPreviousPage();
  }

  onSubmit() {
    if (this.screenType === 'edit') {
      this.editJourneyInfo.name = this.journeyForm.value.title;
      this.editJourneyInfo.shortDescription =
        this.journeyForm.value.shortDescription;
      this.editJourneyInfo.longDescription =
        this.journeyForm.value.longDescription;
      this.editJourneyInfo.requestDate = this.journeyForm.value.startDate;
      this.journeyService
        .updateJourney(this.editJourneyInfo)
        .subscribe((data) => {
          if (data.status) {
            let currentJourneys;
            this.journeyService.journeyList$.subscribe((jList) => {
              currentJourneys = [...jList].filter(
                (data) => data.journeyid !== this.editJourneyInfo.journeyid
              );
            });
            this.journeyService.journeyListSubject.next([
              this.editJourneyInfo,
              ...currentJourneys,
            ]);
            this.utilService.backToPreviousPage();
          }
        });
    } else if (this.screenType === 'create') {
      this.createNewJourney({
        journeyid: `${this.projectidFromUrl}#${this.utilService
          .generateRandomId()
          .toString()}`,
        email_id: localStorage.getItem('email_id'),
        shortDescription: this.journeyForm.value.shortDescription,
        longDescription: this.journeyForm.value.longDescription,
        name: this.journeyForm.value.title,
        projectid: this.projectidFromUrl,
        requestDate: this.journeyForm.value.startDate,
        type: 'journey',
      });
    }
  }
}
