import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-workflow-element-properties',
  templateUrl: './workflow-element-properties.component.html',
  styleUrls: ['./workflow-element-properties.component.scss'],
})
export class WorkflowElementPropertiesComponent implements OnInit {
  @Input() properties;
  constructor() {}
  ngOnInit(): void {
    // this.userStories = this.properties.storyId?.map(st => st.userstoryid);
  }
  userStories: any[];
  testCases: any[];
  dataModels: any[];

}
