import { ModalService } from './../../core/services/modal.service';
import { UtilService } from './../../core/services/util.service';
import { ProjectService } from './../../core/services/project.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { ProjectDTO } from 'src/app/core/model/project.model';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit {
  screenType: string;
  editProjectInfo: any;
  
  constructor(
    private readonly router: Router,
    private readonly projectService: ProjectService,
    private readonly utilService: UtilService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((seg) => {
      this.screenType = seg[2]?.path ?? 'create';
    });

    if (this.screenType === 'edit') {
      this.projectService.editProject$.subscribe((data) => {
        if (data) {
          this.editProjectInfo = {
            ...data,
          };
          this.projectForm.patchValue({
            title: data.name,
            shortDescription: data.shortDescription,
            longDescription: data.longDescription,
            startDate: data.requestDate,
          });
        }
      });
    }
  }

  projectForm = new FormGroup({
    title: new FormControl(''),
    shortDescription: new FormControl(''),
    longDescription: new FormControl(''),
    startDate: new FormControl(''),
    collaborators: new FormControl(''),
  });

  createNewProject(newProject: ProjectDTO) {
    this.projectService.addNewProject(newProject);
    this.router.navigate(['dashboard/project-list']);
  }

  onSubmit() {
    if (this.screenType === 'edit') {
      console.log('edit project', this.editProjectInfo);
      this.editProjectInfo.name = this.projectForm.value.title;
      this.editProjectInfo.shortDescription =
        this.projectForm.value.shortDescription;
      this.editProjectInfo.longDescription =
        this.projectForm.value.longDescription;
      this.editProjectInfo.requestDate = this.projectForm.value.startDate;
      this.projectService
        .updateProject(this.editProjectInfo)
        .subscribe((data) => {
          if (data.status) {
            let currentProjects;
            this.projectService.projectList$.subscribe((projList) => {
              currentProjects = [...projList].filter(
                (data) => data.projectid !== this.editProjectInfo.projectid
              );
            });
            this.projectService.projectListSubject.next([
              this.editProjectInfo,
              ...currentProjects,
            ]);
            this.utilService.backToPreviousPage();
          }
        });
    } else if (this.screenType === 'create') {
      this.createNewProject({
        projectid: this.utilService.generateRandomId(),
        email_id: [localStorage.getItem('email_id')],
        type: 'project',
        longDescription: this.projectForm.value.longDescription,
        shortDescription: this.projectForm.value.shortDescription,
        requestDate: this.projectForm.value.startDate,
        name: this.projectForm.value.title,
      });
    }
  }
}
