import { UtilService } from './../../core/services/util.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  constructor(private readonly utilService: UtilService) {}

  ngOnInit(): void {}

  goBack() {
    this.utilService.backToPreviousPage();
  }
}
