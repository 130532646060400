import { MatListModule } from '@angular/material/list';
import { ModalService } from './../core/services/modal.service';
import { ExperienceFormComponent } from './experience-form/experience-form.component';
import { ExperienceComponent } from './elements/experience/experience.component';
import { EndComponent } from './elements/end/end.component';
import { StartComponent } from './elements/start/start.component';
import { JourneyListComponent } from './journey-list/journey-list.component';
import { JourneyDescriptionComponent } from './journey-description/journey-description.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProjectRoutingModule } from './project-routing.module';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CreateProjectComponent } from '../project/create-project/create-project.component';
import { ProjectListComponent } from '../project/project-list/project-list.component';
import { ProjectDetailsComponent } from '../project/project-details/project-details.component';
import { CreateJourneyComponent } from './create-journey/create-journey.component';
import { DatabaseComponent } from './elements/database/database.component';
import { MessagingComponent } from './elements/messaging/messaging.component';
import { SystemComponent } from './elements/system/system.component';
import { DecisionComponent } from './elements/decision/decision.component';
import { WorkflowDescriptionComponent } from './workflow-description/workflow-description.component';
import { WorkflowElementPropertiesComponent } from '../workflow-element-properties/workflow-element-properties.component';
import { SafePipe } from '../shared/pipes/safe.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AddCollaboratorComponent } from './add-collaborator/add-collaborator.component';

@NgModule({
  declarations: [
    CreateProjectComponent,
    ProjectListComponent,
    ProjectDetailsComponent,
    CreateJourneyComponent,
    JourneyDescriptionComponent,
    JourneyListComponent,
    StartComponent,
    EndComponent,
    ExperienceComponent,
    ExperienceFormComponent,
    DatabaseComponent,
    MessagingComponent,
    SystemComponent,
    DecisionComponent,
    WorkflowDescriptionComponent,
    WorkflowElementPropertiesComponent,
    SafePipe,
    AddCollaboratorComponent,
  ],
  imports: [
    SharedModule,
    ProjectRoutingModule,
    DragDropModule,
    MatChipsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [ModalService],
  exports: [CreateProjectComponent, ProjectListComponent],
})
export class ProjectModule {}
