import { ProjectService } from './../../core/services/project.service';
import { Component, OnInit } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-add-collaborator',
  templateUrl: './add-collaborator.component.html',
  styleUrls: ['./add-collaborator.component.scss'],
})
export class AddCollaboratorComponent implements OnInit {
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  testCaseSelectedItems: any[];
  testCaseDropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    selectAllText: string;
    unSelectAllText: string;
    itemsShowLimit: number;
    allowSearchFilter: boolean;
  };

  collaboratorList: any[] = [];
  existingCollaborators: any[] = [];

  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];

  keywords = [];

  constructor(private readonly projectService: ProjectService) {}

  addCollaboratorsForm = new FormGroup({
    emailList: new FormControl(''),
    projectList: new FormControl(''),
  });

  ngOnInit(): void {
    this.testCaseDropdownSettings = {
      singleSelection: false,
      idField: 'projectid',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.projectService.projectList$.subscribe((data) => {
      this.collaboratorList = [...data];
    });

    this.collaboratorList.map((c) => (c.isSelected = false));

    this.projectService.getExistingCollaborators(localStorage.getItem('email_id')).subscribe(data => {
      console.log('collab list ', data)
      if (data.status) {
        this.existingCollaborators = [...data.list]
      }
    })
  }

  add(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;

    // Add our keyword
    if ((value || '').trim()) {
      this.keywords.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(keyword: any): void {
    let index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  onItemSelect(item: any) {
    // if (item.hasOwnProperty('datamodelid')) {
    //   this.dataModelList = this.dataModelList.map((dm) => {
    //     if (dm.datamodelid === item.datamodelid) {
    //       dm.isSelected = true;
    //       return dm;
    //     }
    //   });
    // }
  }

  onSubmit() {
    const emailList = this.addCollaboratorsForm && this.addCollaboratorsForm.value && this.addCollaboratorsForm.value.emailList
    const userLoggedInEmail = localStorage.getItem('email_id')
    const payload = {
      email: userLoggedInEmail,
      collaboratorsList: this.keywords,
      projectList: this.testCaseSelectedItems
    }
    this.projectService
      .shareProjectWithCollaborators(payload)
      .subscribe((data) => {
        if (data.status) {
          alert('Invited Successfully');
        } else {
          alert('Please try again something went wrong');
        }
      });
  }
}
