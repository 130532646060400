import { UtilService } from './util.service';
import { BehaviorSubject } from 'rxjs';
import { baseAPIPath } from 'src/config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class WorkFlowService {
  constructor(
    private readonly http: HttpClient,
    private readonly utilService: UtilService
  ) {}

  workFlowSubject = new BehaviorSubject<any>([
    {
      type: 'start',
      id: `start#${this.utilService.generateRandomId()}`,
      status: 1,
    },
  ]);
  workFlow$ = this.workFlowSubject.asObservable();

  formdataSubject = new BehaviorSubject<any>({});
  formData$ = this.formdataSubject.asObservable();

  selectedElementOfWorkFlow: any;

  getWorkFlowFromDB(emailId: string, projectId: string, journeyId: string) {
    const reqPayload = {
      email_id: emailId,
      projectid: projectId,
      journeyid: journeyId,
    };
    return this.http.post<any>(`${baseAPIPath}/getWorkFlow`, reqPayload);
  }

  addWorkFlow(workFlowDetails: any) {
    return this.http.post<any>(`${baseAPIPath}/workflow`, workFlowDetails);
  }

  saveExpFormData(formData: any) {
    console.log(formData);
    this.formdataSubject.next({ ...formData });
  }

  updateWorkFlowToDB(wfInfo: any) {
    return this.http.put<any>(`${baseAPIPath}/workflow`, wfInfo);
  }
}
