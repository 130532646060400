import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TreelistService {
  public newId = 30;
  private treeToggler = new Subject<any>();
  treeToggler$ = this.treeToggler.asObservable();
  private hideChilrenToggler = new Subject<any>();
  hideChilrenToggler$ = this.hideChilrenToggler.asObservable();
  private hoverNodeToggler = new Subject<any>();
  hoverNodeToggler$ = this.hoverNodeToggler.asObservable();

  hideChildrenOfIds = [];
  public treelist;
  public currentlySelectedTree;
  public currentlySelectedTreeId;
  public currentlyHoveringId;
  data = {
    name: 'root',
    children: [],
  };
  constructor() {}

  setCurrentlyHoveringId(id) {
    this.currentlyHoveringId = id;
    this.hoverNodeToggler.next(true);
  }

  getCurrentlyHoveringId() {
    return this.currentlyHoveringId;
  }

  setCurrentlySelectedTree(t) {
    this.currentlySelectedTree = t;
    this.currentlySelectedTreeId = t[0][0].id;
    this.treeToggler.next(true);
  }

  setTreeList(tl) {
    this.treelist = tl;
  }

  clearHiddenChildren() {
    this.hideChildrenOfIds = [];
  }

  hideNodeChilren(id) {
    if (this.hideChildrenOfIds.includes(id)) {
      const index = this.hideChildrenOfIds.indexOf(String(id));
      this.hideChildrenOfIds.splice(index, 1);
    } else {
      this.hideChildrenOfIds.push(id);
      this.hideChilrenToggler.next(true);
      console.log(this.hideChildrenOfIds);
    }
  }

  // addChild(id, name) {
  //   let childernData = {
  //     name: name,
  //     description: 'node description test',
  //     id: this.newId,
  //     datecreated: '2020-05-16',
  //     showchildren: '1',
  //     children: [],
  //   }
  //   this.newId = this.newId + 1
  //   this.data.children.push(childernData)
  // }

  getDatamodelDetails() {
    return this.data;
  }

  addChild(id, nodeName?: string) {
    debugger;
    console.log(nodeName);
    console.log('add child to ' + id);
    console.log(this.currentlySelectedTree[0][0]);

    const childArray = this.getChildrenOfID(
      this.currentlySelectedTree[0][0],
      id
    );
    console.log(childArray);
    childArray.push({
      name: nodeName,
      id: this.newId,
      description: '',
      datecreated: new Date(),
      showchildren: '1',
      children: [],
    });
    this.newId = this.newId + 1;
  }

  getChildrenOfID(tree, id) {
    const stack = [tree];
    while (stack.length) {
      const current = stack.pop();
      if (current.id === id) {
        return current.children;
      }
      stack.push(...current.children);
    }
  }

  deleteChild(id) {
    console.log('delete this' + id);
    // console.log(this.currentlySelectedTree[0][0]);

    // const childArray = this.getChildrenOfID(
    //   this.currentlySelectedTree[0][0],
    //   id
    // );
    console.log(this.currentlySelectedTree);
    this.currentlySelectedTree = this.currentlySelectedTree.filter(
      (data) => data.id !== id
    );
  }
}
