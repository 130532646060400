<div class="ui-tool-journey-description-menu">
  <div style="display: flex">
    <app-back-button></app-back-button>
    <h2>Journey: {{ journeyName }}</h2>
  </div>
  <div
    *ngIf="!hideForNormalUser"
    style="width: 12%; display: flex; justify-content: space-between"
  >
    <button
      *ngIf="!isUpdateVisible"
      type="button"
      class="btn btn-success"
      (click)="onJourneySave()"
    >
      Save
    </button>
    <button
      *ngIf="isUpdateVisible"
      type="button"
      class="btn btn-warning"
      (click)="onJourneyUpdate()"
    >
      Update
    </button>
    <button type="button" class="btn btn-danger" (click)="resetWorkFlow()">
      Reset
    </button>
  </div>
</div>

<div class="container mt-4">
  <div class="main-tab-header">
    <!--this is section of show selected element start-->
    <div class="start-preview" *ngIf="activeElement === 'start'">Start</div>
    <div *ngIf="activeElement === 'experience'" class="exp">
      <div class="custom-drop-input-preview">
        <h5>
          <span>Experience</span>
        </h5>

        <input
          type="text"
          [value]="activedWorkFlow?.taskDescription"
          [disabled]="true"
        />
      </div>
    </div>
    <div *ngIf="activeElement === 'system'" class="sys">
      <div class="custom-drop-input-preview">
        <h5>
          <span>System</span>
        </h5>
        <input
          type="text"
          [value]="activedWorkFlow?.taskDescription"
          [disabled]="true"
        />
      </div>
    </div>
    <div *ngIf="activeElement === 'database'" class="db">
      <div class="custom-drop-input-preview">
        <h5>
          <span>Database</span>
        </h5>
        <input
          type="text"
          [value]="activedWorkFlow?.taskDescription"
          [disabled]="true"
        />
      </div>
    </div>
    <div *ngIf="activeElement === 'messaging'" class="ms">
      <div class="custom-drop-input-preview">
        <h5>
          <span>Messaging</span>
        </h5>
        <input
          type="text"
          [value]="activedWorkFlow?.taskDescription"
          [disabled]="true"
        />
      </div>
    </div>
    <div *ngIf="activeElement === 'decision'" class="dc">
      <div class="custom-drop-input-preview">
        <h5>
          <span>Decision</span>
        </h5>
        <input
          type="text"
          [value]="activedWorkFlow?.taskDescription"
          [disabled]="true"
        />
      </div>
    </div>
    <!--this is section of show selected element end-->
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          [ngClass]="{ active: activeTab === 'workflow' }"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
        >
          WorkFlow
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          [ngClass]="{ active: activeTab === 'experience' }"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="true"
        >
          Experience
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-contact-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-contact"
          type="button"
          role="tab"
          aria-controls="pills-contact"
          aria-selected="false"
          (click)="renderFilteredDataMode()"
        >
          Data Model
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-userstories-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-userstories"
          type="button"
          role="tab"
          aria-controls="pills-userstories"
          aria-selected="false"
          (click)="renderFilteredUserStories()"
        >
          User Stories
        </button>
      </li>

      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-testscenarios-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-testscenarios"
          type="button"
          role="tab"
          aria-controls="pills-testscenarios"
          aria-selected="false"
          (click)="renderFilteredTestCases()"
        >
          Test Scenarios
        </button>
      </li>
    </ul>
  </div>
  <div class="tab-content" id="pills-tabContent">
    <div
      class="tab-pane fade"
      [ngClass]="{ 'show active': activeTab === 'workflow' }"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div>
        <div>
          <div class="container mt-4" style="width: 85%">
            <h4 *ngIf="!hideForNormalUser">Elements</h4>
            <div
              class="ui-tool-element-container"
              #elementList="cdkDropList"
              cdkDropList
              [cdkDropListData]="elements"
              cdkDropListConnectedTo="workFlow"
              id="elements"
              *ngIf="!hideForNormalUser"
            >
              <div class="ui-tool-elements">
                <app-experience cdkDrag></app-experience>
                <app-messaging cdkDrag></app-messaging>
                <app-system cdkDrag></app-system>
                <!-- <app-decision cdkDrag></app-decision> -->
                <app-database cdkDrag></app-database>
                <app-end cdkDrag></app-end>
              </div>
            </div>
            <p class="mt-4 drag-header" *ngIf="!hideForNormalUser">
              Drag and drop elements below to create your workflow:
            </p>
            <div
              class="ui-tool-flow-drawing-area"
              cdkDropList
              #workFlowList="cdkDropList"
              [cdkDropListData]="workFlow"
              cdkDropListConnectedTo="elements"
              style="display: flex"
              id="workFlow"
              (cdkDropListDropped)="drop($event)"
            >
              <div *ngFor="let item of workFlow">
                <ng-container *ngIf="formVisibility">
                  <app-experience-form
                    #expForm
                    [elId]="item.id"
                    [taskDescription]="item.taskDescription"
                    (closeForm)="changeFormVis($event)"
                    [propertyLink]="item.propertyLink"
                    [userStoryList]="userStories"
                    [testCaseList]="testScenaios"
                    [dataModelList]="dataModels"
                  ></app-experience-form>
                </ng-container>
                <app-start
                  [key]="item.id"
                  *ngIf="item.type === 'start'"
                ></app-start>
                <div
                  [key]="item.id"
                  *ngIf="item.type === 'experience'"
                  class="exp"
                >
                  <div
                    class="custom-drop-input"
                    [ngClass]="{ selected: activeElement === 'experience' }"
                  >
                    <h5>
                      <span (click)="goToWorkFlow(item)">Experience</span>
                      <ngContainer *ngIf="!hideForNormalUser">
                        <i
                          class="fa fa-times"
                          (click)="deleteElement(item.id)"
                          style="float: right; padding: 5px; font-size: 1rem"
                        ></i>
                        <i
                          style="float: right; padding: 5px; font-size: 1rem"
                          class="fa fa-edit"
                          (click)="editElement(item)"
                        ></i>
                      </ngContainer>
                    </h5>

                    <textarea
                      type="text"
                      [id]="item.id"
                      [value]="item.taskDescription"
                      [disabled]="true"
                    ></textarea>
                  </div>
                </div>
                <div [key]="item.id" *ngIf="item.type === 'system'" class="sys">
                  <div
                    class="custom-drop-input"
                    [ngClass]="{ selected: activeElement === 'system' }"
                  >
                    <h5>
                      <span (click)="goToWorkFlow(item)">System</span>
                      <ngContainer *ngIf="!hideForNormalUser">
                        <i
                          class="fa fa-times"
                          (click)="deleteElement(item.id)"
                          style="float: right; padding: 5px; font-size: 1rem"
                        ></i>
                        <i
                          style="float: right; padding: 5px; font-size: 1rem"
                          class="fa fa-edit"
                          (click)="editElement(item)"
                        ></i>
                      </ngContainer>
                    </h5>
                    <textarea
                      type="text"
                      [id]="item.id"
                      [value]="item.taskDescription"
                      [disabled]="true"
                    ></textarea>
                  </div>
                </div>
                <div
                  [key]="item.id"
                  *ngIf="item.type === 'database'"
                  class="db"
                >
                  <div
                    class="custom-drop-input"
                    [ngClass]="{ selected: activeElement === 'database' }"
                  >
                    <h5>
                      <span (click)="goToWorkFlow(item)">Database</span>
                      <ngContainer *ngIf="!hideForNormalUser">
                        <i
                          class="fa fa-times"
                          (click)="deleteElement(item.id)"
                          style="float: right; padding: 5px; font-size: 1rem"
                        ></i>
                        <i
                          style="float: right; padding: 5px; font-size: 1rem"
                          class="fa fa-edit"
                          (click)="editElement(item)"
                        ></i>
                      </ngContainer>
                    </h5>
                    <textarea
                      type="text"
                      [id]="item.id"
                      [value]="item.taskDescription"
                      [disabled]="true"
                    ></textarea>
                  </div>
                </div>
                <div
                  [key]="item.id"
                  *ngIf="item.type === 'messaging'"
                  class="ms"
                >
                  <div
                    class="custom-drop-input"
                    [ngClass]="{ selected: activeElement === 'messaging' }"
                  >
                    <h5>
                      <span (click)="goToWorkFlow(item)">Messaging</span>
                      <ngContainer *ngIf="!hideForNormalUser">
                        <i
                          class="fa fa-times"
                          (click)="deleteElement(item.id)"
                          style="float: right; padding: 5px; font-size: 1rem"
                        ></i>
                        <i
                          style="float: right; padding: 5px; font-size: 1rem"
                          class="fa fa-edit"
                          (click)="editElement(item)"
                        ></i>
                      </ngContainer>
                    </h5>
                    <textarea
                      type="text"
                      [id]="item.id"
                      [value]="item.taskDescription"
                      [disabled]="true"
                    ></textarea>
                  </div>
                </div>
                <div
                  [key]="item.id"
                  *ngIf="item.type === 'decision'"
                  class="dc"
                >
                  <div
                    class="custom-drop-input"
                    [ngClass]="{ selected: activeElement === 'decision' }"
                  >
                    <h5>
                      <span (click)="goToWorkFlow(item)">Decision</span>
                      <ngContainer *ngIf="!hideForNormalUser">
                        <i
                          class="fa fa-times"
                          (click)="deleteElement(item.id)"
                          style="float: right; padding: 5px; font-size: 1rem"
                        ></i>
                        <i
                          style="float: right; padding: 5px; font-size: 1rem"
                          class="fa fa-edit"
                          (click)="editElement(item)"
                        ></i>
                      </ngContainer>
                    </h5>
                    <textarea
                      type="text"
                      [id]="item.id"
                      [value]="item.taskDescription"
                      [disabled]="true"
                    ></textarea>
                  </div>
                </div>
                <app-end *ngIf="item.type === 'end'"></app-end>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="tab-pane fade"
      [ngClass]="{ 'show active': activeTab === 'experience' }"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div class="flex-container-custom">
        <div style="width: 50%">
          <h4>Experience</h4>
          <iframe
            width="790"
            height="700"
            [src]="elementProperties.propertyLink | safe"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
        <app-workflow-element-properties
          [properties]="elementProperties"
        ></app-workflow-element-properties>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div class="flex-container-custom">
        <div style="width: 50%">
          <h4>Data Model - Customer Profile</h4>
          <ng-container *ngFor="let dt of filteredDataModel">
            <div class="accordion-item">
              <h2 class="accordion-header" id="dt.datamodelid">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {{ dt.name }}
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="dt.datamodelid"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <app-treepane
                    [treelist]="currentlySelectedTree"
                    [isEditable]="false"
                  ></app-treepane>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <app-workflow-element-properties
          [properties]="elementProperties"
        ></app-workflow-element-properties>
      </div>
    </div>

    <div
      class="tab-pane fade"
      id="pills-userstories"
      role="tabpanel"
      aria-labelledby="pills-userstories-tab"
    >
      <div class="flex-container-custom">
        <div style="width: 50%">
          <h4>User Stories</h4>
          <div class="mt-4">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let userStory of filteredUserStories">
                  <td>{{ userStory.name }}</td>
                  <td>{{ userStory.storyDescription }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <app-workflow-element-properties
          [properties]="elementProperties"
        ></app-workflow-element-properties>
      </div>
    </div>

    <div
      class="tab-pane fade"
      id="pills-testscenarios"
      role="tabpanel"
      aria-labelledby="pills-testscenarios-tab"
    >
      <div class="flex-container-custom">
        <div style="width: 50%">
          <h4>Test Scenarios</h4>
          <div class="mt-4">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let testScenaio of filteredTestCases">
                  <td>{{ testScenaio.name }}</td>
                  <td>{{ testScenaio.testCaseDescription }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <app-workflow-element-properties
          [properties]="elementProperties"
        ></app-workflow-element-properties>
      </div>
    </div>
  </div>
</div>
