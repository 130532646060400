import { AuthService } from 'src/app/core/services/auth.service';
import { ProjectService } from './../../core/services/project.service';
import { JourneyService } from './../../core/services/journey.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-card',
  templateUrl: './custom-card.component.html',
  styleUrls: ['./custom-card.component.scss'],
})
export class CustomCardComponent implements OnInit {
  @Output() deleteClickHandler = new EventEmitter<string>();
  @Input() details: any;
  hideForNormalUser: boolean = false;
  constructor(
    private readonly router: Router,
    private readonly journeyService: JourneyService,
    private readonly projectService: ProjectService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.cardDetails = { ...this.details };
    this.hideForNormalUser = this.authService.isNormalUser();
  }

  cardDetails: any;

  viewDetails(details: any) {
    if (details.type === 'project') {
      this.journeyService.getJourneyFromAPI(
        localStorage.getItem('email_id'),
        details.projectid
      );
      this.router.navigate([
        'dashboard/project-description',
        details.projectid,
      ]);
    } else if (details.type === 'journey') {
      this.router.navigate([
        'dashboard/journey-description',
        details.journeyid.split('#')[1],
      ]);
    }
  }

  onDelete(details: any) {
    const deleteIt = confirm(`Do you want to delete ${this.cardDetails.type}?`);
    if (deleteIt) {
      if (details.type === 'project') {
        this.deleteClickHandler.emit(details.projectid);
      } else if (details.type === 'journey') {
        this.deleteClickHandler.emit(details.journeyid);
      }
    } else {
      console.log('dont delete it');
    }
  }

  onEdit(details: any) {
    if (this.cardDetails.type === 'project') {
      this.projectService.editProjectSubject.next({ ...this.cardDetails });
      this.router.navigate([
        'dashboard/edit-project',
        details.projectid,
        'edit',
      ]);
    } else if (this.cardDetails.type === 'journey') {
      this.journeyService.editJourneySubject.next({ ...this.cardDetails });
      this.router.navigate([
        'dashboard/edit-journey',
        details.journeyid,
        'edit',
      ]);
    }
  }
}
