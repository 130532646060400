import { WorkFlowDetailsService } from './services/workFlowDetails.service';
import { WorkFlowService } from './services/workFlow.service';
import { SideNavService } from './services/side-nav.service.service';
import { TreelistService } from './services/treelist.service';
import { UserStoryService } from './services/user-story.service';
import { DataModelService } from './services/datamodel.service';
import { AuthService } from './services/auth.service';
import { JourneyService } from './services/journey.service';
import { ProjectService } from './services/project.service';
import { UtilService } from './services/util.service';
import { APIService } from './services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    APIService,
    UtilService,
    ProjectService,
    JourneyService,
    AuthService,
    DataModelService,
    UserStoryService,
    TreelistService,
    SideNavService,
    WorkFlowService,
    WorkFlowDetailsService,
  ],
})
export class CoreModule {}
