<div class="pd-25" style="width: 300px; border-radius: 30px; margin-top: 40px">
  <h6 class="center">Properties</h6>
  <div class="text-block">
    <label>Task Description</label>
    <p>{{ properties.taskDescription }}</p>
  </div>
  <div class="text-block">
    <label>Prototype Link</label>
    <p>{{ properties.propertyLink }}</p>
  </div>
  <div class="text-block">
    <label>Data Model IDs</label>
    <p>
      <span *ngFor="let dm of properties.dataModelName">{{ dm.name }}</span>
    </p>
  </div>
  <div class="text-block">
    <label>User Story IDs</label>
    <p>
      <span *ngFor="let st of properties.storyId">{{ st.name }}</span>
    </p>
  </div>
  <div class="text-block">
    <label>Test Scenarios IDs</label>
    <p>
      <span *ngFor="let ts of properties.testScenarios">{{ ts.name }}</span>
    </p>
  </div>
</div>
