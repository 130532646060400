import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NodeComponent } from './node/node.component';
import { NotificationComponent } from './notification/notification.component';
import { DashboardModule } from './../dashboard/dashboard.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCardComponent } from './custom-card/custom-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BackButtonComponent } from '../shared/back-button/back-button.component';
import { HttpClientModule } from '@angular/common/http';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { TreepaneComponent } from './treepane/treepane.component';
import { CustomNavComponent } from './custom-nav/custom-nav.component';

@NgModule({
  declarations: [
    CustomCardComponent,
    BackButtonComponent,
    CustomHeaderComponent,
    NotificationComponent,
    TreepaneComponent,
    NodeComponent,
    CustomNavComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MatAutocompleteModule,
    MatInputModule,
  ],
  exports: [
    CustomCardComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BackButtonComponent,
    CustomHeaderComponent,
    TreepaneComponent,
    NodeComponent,
    CustomNavComponent,
  ],
})
export class SharedModule {}
