import { BehaviorSubject } from 'rxjs';
import { baseAPIPath } from 'src/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserStoryService {
  constructor(private readonly http: HttpClient) {}

  userStoriesSub = new BehaviorSubject<any[]>([]);

  userStories$ = this.userStoriesSub.asObservable();

  getUserStoriesFromDB(email, projectId) {
    return this.http.post<any>(`${baseAPIPath}/userstory`, {
      email_id: email,
      projectid: projectId,
    });
  }

  addUserStory(userStory: any) {
    return this.http.post<any>(`${baseAPIPath}/adduserstory`, userStory);
  }

  fetchProjectIssues(projectKey: string) {
    this.http
      .get<any>(`${baseAPIPath}/getProject/?project=${projectKey}`)
      .pipe(
        map((data) => {
          return data.issueTypes.map((data) => ({
            storyId: data.id,
            storyDescription: data.description,
          }));
        })
      )
      .subscribe((data) => {
        const previousData = this.userStoriesSub.value;
        this.userStoriesSub.next([...previousData, ...data]);
      });
  }

  deleteUserStoryFromDB(id: string) {
    return this.http.request<any>('delete', `${baseAPIPath}/deleteuserstory`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { userstoryid: id },
    });
  }

  updateUserStory(story: any) {
    return this.http.put<any>(`${baseAPIPath}/userstory`, story);
  }
}
