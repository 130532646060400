<nav class="navbar navbar-expand-lg fixed-top navbar-light bg-light">
  <div class="container-fluid">
    <!-- <a class="navbar-brand" [routerLink]="['/dashboard']">Home</a> -->
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['project']">Projects</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['add-collaborator']"
            >Add Collaborator</a
          >
        </li>
      </ul>
      <form [formGroup]="formGroup">
        <mat-form-field [style.width.px]="1200">
          <mat-label> Search Your Projects Here </mat-label>
          <input
            class="form-control"
            formControlName="searchControl"
            matInput
            type="text"
            [matAutocomplete]="auto"
            (keyup.enter)="initForm()"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngIf="showNoDataMessage()" value="No Data Found">
              No Data Found
            </mat-option>
            <mat-option
              *ngFor="let item of filteredOptions"
              [value]="item.name"
            >
              <a
                [routerLink]="[
                  '/dashboard/project-description/' + item.projectid
                ]"
                >{{ item.name }}</a
              >
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
    <div class="d-flex" style="align-items: baseline">
      <div (click)="toggleNotification()">
        <i class="fa fa-bell custom-mr-20"></i>
        <app-notification [showMe]="isNotificationVisible"></app-notification>
      </div>

      <span class="custom-mr-20">{{ loggedInUsername }}</span>
      <button class="btn btn-primary" type="button" (click)="logout()">
        Sign Off
      </button>
    </div>
  </div>
</nav>
