import { Router } from '@angular/router';
import { ProjectDTO } from './../model/project.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseAPIPath } from 'src/config';

@Injectable()
export class ProjectService {
  email: string;
  constructor(private readonly http: HttpClient) {
    this.email = localStorage.getItem('email_id');
    this.getProjectsFromAPI$({
      email_id: this.email,
    });
  }

  projectListSubject = new BehaviorSubject<ProjectDTO[]>([]);
  projectList$ = this.projectListSubject.asObservable();

  editProjectSubject = new BehaviorSubject<any>({});
  editProject$ = this.editProjectSubject.asObservable();

  getProjectsFromAPI$(req) {
    return this.http
      .post<any>(`${baseAPIPath}/projects`, req)
      .subscribe((data) => {
        this.projectListSubject.next([...data.projects]);
      });
  }

  updateProject(project: any) {
    return this.http.put<any>(`${baseAPIPath}/project`, project);
  }

  addNewProject(project: ProjectDTO) {
    this.http
      .post<any>(`${baseAPIPath}/addproject`, project)
      .subscribe((data) => {
        if (data.status) {
          const currentProject = this.projectListSubject.value;
          this.projectListSubject.next([...currentProject, project]);
        } else {
          alert('Something went wrong, try again');
        }
      });
  }

  deleteProjectFromDB(projectid: string) {
    return this.http.request<any>('delete', `${baseAPIPath}/project`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { projectid: projectid },
    });
  }

  shareProjectWithCollaborators(collaborators: any) {
    return this.http.post<any>(
      `${baseAPIPath}/addCollabs`,
      collaborators
    );
  }

  searchProject(projectName: string) {
    return this.http.get<any>(`${baseAPIPath}/search/?${projectName}`);
  }

  getExistingCollaborators(info: any) {
    console.log('payload info ', info)
    return this.http.post<any>(`${baseAPIPath}/getCollabList`, {emailId: info});
  }
}
