<div class="ux-tool-experience-form">
  <div class="ui-tool-flex-container-space-between">
    <h2>Properties</h2>
    <i class="fa fa-times" (click)="closeFormFn()"></i>
  </div>
  <form class="mt-4" [formGroup]="experienceForm" (ngSubmit)="onSubmit()">
    <div>
      <label class="form-label">Task Description</label>
      <input
        type="text"
        class="form-control"
        formControlName="taskDescription"
        [value]="taskDescription"
        required
      />
    </div>
    <div>
      <label class="form-label">Property Link</label>
      <input type="text" class="form-control" formControlName="propertyLink" />
    </div>
    <div>
      <label class="form-label">Data Model Name</label>
      <ng-multiselect-dropdown
        formControlName="dataModelName"
        [placeholder]="'Select Data models'"
        [settings]="dataModeldropdownSettings"
        [data]="dataModelList"
        [(ngModel)]="dataModelselectedItems"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
      <!-- <select class="form-control" multiple formControlName="dataModelName">
        <option
          *ngFor="let dm of dataModelList"
          [id]="dm.datamodelid"
          [ngValue]="dm.datamodelid"
          [selected]="dm.isSelected"
        >
          {{ dm.name }}
        </option>
      </select> -->
    </div>
    <div>
      <label class="form-label">User Story IDs</label>
      <ng-multiselect-dropdown
        formControlName="storyId"
        [placeholder]="'Select User stories'"
        [settings]="userStorydropdownSettings"
        [data]="userStoryList"
        [(ngModel)]="userStoryselectedItems"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
      <!-- <select class="form-control" multiple formControlName="storyId">
        <option
          *ngFor="let us of userStoryList"
          [id]="us.userstoryid"
          [ngValue]="us.userstoryid"
          [selected]="us.isSelected"
        >
          {{ us.name }}
        </option>
      </select> -->
    </div>
    <div>
      <label class="form-label">Test Scenarios</label>
      <ng-multiselect-dropdown
        formControlName="testScenarios"
        [placeholder]="'Select test scenarios'"
        [settings]="testCaseDropdownSettings"
        [data]="testCaseList"
        [(ngModel)]="testCaseSelectedItems"
        (onSelect)="onItemSelect($event)"
        (onSelectAll)="onSelectAll($event)"
      >
      </ng-multiselect-dropdown>
      <!-- <select class="form-control" multiple formControlName="testScenarios">
        <option
          *ngFor="let ts of testCaseList"
          [id]="ts.testcaseid"
          [ngValue]="ts.testcaseid"
          [selected]="ts.isSelected"
        >
          {{ ts.name }}
        </option>
      </select> -->
    </div>
    <div class="ui-tool-flex-container center">
      <button
        type="submit"
        class="btn btn-success"
        [disabled]="!experienceForm.valid"
      >
        Save
      </button>
    </div>
  </form>
</div>
