import { BackendLayoutComponent } from './layouts/backend-layout/backend-layout.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DEFAULT_ROUTES } from './routes/default-layout-route';
import { BACKEND_ROUTES } from './routes/backend-layout-route';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: DEFAULT_ROUTES,
  },
  {
    path: 'dashboard',
    component: BackendLayoutComponent,
    children: BACKEND_ROUTES,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      // relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
