import { ProjectService } from './../../core/services/project.service';
import { AuthService } from './../../core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent implements OnInit {
  isNotificationVisible = false;
  loggedInUsername: string;
  searchResults: any[] = [];
  searchString: string = '';
  title = 'autocomplete';

  options: any[] = [];

  filteredOptions;

  formGroup: FormGroup;

  constructor(
    private readonly authService: AuthService,
    private readonly projectService: ProjectService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loggedInUsername = localStorage.getItem('email_id');
    this.formGroup = this.fb.group({
      searchControl: [''],
    });
    this.initForm();
    this.getNames();
  }

  logout() {
    this.authService.logout();
  }

  toggleNotification() {
    console.log('something');
    this.isNotificationVisible = !this.isNotificationVisible;
  }

  handleSearch(searchString: string) {
    let searchStr = searchString.trim().toLowerCase();
    this.projectService.projectList$.subscribe((data) => {
      this.searchResults = [...data].filter((obj) =>
        Object.values(obj).some((val) => val.includes(searchStr))
      );
      console.log(this.searchResults);
    });
  }

  initForm() {
    this.formGroup
      .get('searchControl')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((response) => {
        console.log(response, 'typing');
        if (response && response.length > 0) {
          console.log('data is ', response);
          this.filterData(response);
        } else {
          this.filteredOptions = [];
        }
      });
  }

  filterData(enteredData) {
    let searchStr = enteredData.trim().toLowerCase();
    this.projectService.projectList$.subscribe((data) => {
      this.filteredOptions = [...data].filter((obj) =>
        Object.values(obj).some((val) => val.includes(searchStr))
      );
      console.log(this.filteredOptions);
    });
  }

  getNames() {
    this.projectService.projectList$.subscribe((response) => {
      this.options = response;
    });
  }

  showNoDataMessage() {
    if (
      this.filteredOptions?.length === 0 &&
      this.formGroup.get('searchControl').value.trim() !== ''
    ) {
      return true;
    } else {
      return false;
    }
  }
}
