<div class="add-collaborator-container container">
  <h4>Add Collaborator</h4>
  <div class="container">
    <form [formGroup]="addCollaboratorsForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <!-- <label class="form-label">Enter Email</label> -->
        <mat-form-field class="demo-chip-list">
          <mat-chip-list #chipList>
            <mat-chip
              *ngFor="let keyword of keywords"
              [selectable]="selectable"
              [removable]="removable"
              (remove)="remove(keyword)"
            >
              {{ keyword }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              formControlName="emailList"
              placeholder="Enter Email"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
      <div class="form-group">
        <label class="form-label">Choose Project</label>

        <ng-multiselect-dropdown
          formControlName="projectList"
          [placeholder]="'Select Projects'"
          [settings]="testCaseDropdownSettings"
          [data]="collaboratorList"
          [(ngModel)]="testCaseSelectedItems"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
        >
        </ng-multiselect-dropdown>
      </div>

      <div class="mt-4">
        <button
          class="submit"
          class="btn btn-success"
          [disabled]="!addCollaboratorsForm.valid"
        >
          Invite Collaborators
        </button>
      </div>
    </form>
  </div>

  <div class="mt-4">
    <h4>Existing Collaborators</h4>
    <ul>
      <div *ngIf="existingCollaborators.length === 0">
        No collaborators found
      </div>
      <li *ngFor="let people of existingCollaborators">
        <h3>To Whom with you shared: </h3>
        <h5>{{ people.collaboratorsList.toString() }}</h5>
        <h3>List of projects what you have shared:</h3>
        <h5  *ngFor="let project of people.projectList"> {{ project.name }}</h5>
      </li>
    </ul>
  </div>
</div>
