import { AuthService } from 'src/app/core/services/auth.service';
import { ProjectService } from './../../core/services/project.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectDTO } from 'src/app/core/model/project.model';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  hideForNormalUser: boolean = false;
  constructor(
    private readonly router: Router,
    private readonly projectService: ProjectService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.projectService.projectList$.subscribe((data) => {
      this.projectList = [...data];
    });

    this.hideForNormalUser = this.authService.isNormalUser();
    console.log('hide', this.hideForNormalUser);
  }

  projectList: ProjectDTO[];

  goToCreateProject() {
    this.router.navigate(['/dashboard/create-project']);
  }

  deleteProject(id: string) {
    this.projectService.deleteProjectFromDB(id).subscribe((data) => {
      if (data.status) {
        this.projectList = this.projectList.filter(
          (project) => project.projectid !== id
        );
        this.projectService.projectListSubject.next([...this.projectList]);
      } else {
        alert('Something went wrong, Please try again');
      }
    });
  }
}
