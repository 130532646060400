<div class="container poject-container">
  <div style="display: flex; justify-content: space-between">
    <h2>{{ hideForNormalUser ? "View" : "Create" }} New Project</h2>
    <button
      *ngIf="!hideForNormalUser"
      type="submit"
      class="btn btn-success"
      (click)="goToCreateProject()"
    >
      <i class="fa fa-plus"></i>
      New Project
    </button>
  </div>
  <div class="mt-4">
    <div class="container ui-tool-flex-container">
      <ng-container *ngFor="let projectDetails of projectList">
        <app-custom-card
          [details]="projectDetails"
          (deleteClickHandler)="deleteProject($event)"
        ></app-custom-card>
      </ng-container>
    </div>
  </div>
</div>
